$tooltip-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
$photo-icon-color: #797ff2;
$no-data-color: #a5a5a5;
$ai-color: #797ff2;
$ai-container: #6468fe17;

$admin-bg: #f5f5f9;
$admin-section-bg: white;
$admin-menu-color: #e34f21;
$admin-menu-active-color: #fcdbd2;
$admin-color-warning: #ffc107;
$admin-color-bg-warning: #fff1c8;
$admin-color-info: #17a2b8;
$admin-color-bg-info: #cff9ff;

/**color palette result from https://color.adobe.com/es/create/image after uploading logo*/
$logo-color-1: #636af2; //used as $purple in $primary
$logo-color-2: #797ff2;
$logo-color-3: #0db1ff; //used as $cyan in $info
$logo-color-4: #13f2dc; //used as $teal in $success

/**To change the colors of the buttons and all features with primary and info
 go to sass>bootstrap>_variables and start in line 38*/

$edit-icon: #c7d4fe;
