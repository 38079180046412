@use 'globals' as g;
@use './util/' as u;

.admin-bg {
  background-color: g.$admin-bg;
  // min-height: 1080px;
}

.admin-section {
  box-shadow: rgba(67, 89, 113, 0.12) 0px 2px 6px 0px;
  border-radius: 10px;
  background-color: g.$admin-section-bg;

  .btn-link {
    &:hover {
      text-decoration: none;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .list-group-item-header {
    &.active {
      background-color: g.$admin-menu-active-color;
      border-color: g.$admin-menu-active-color;
      color: g.$admin-menu-color !important;
      font-weight: bold;
    }
  }
}

.admin-main {
  margin-left: calc(100% / 12 * 3);
  @include u.media-breakpoint-up(lg) {
    margin-left: calc(100% / 12 * 2);
  }
}

.admin-left-menu {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  .list-group-item {
    &.active {
      background-color: g.$admin-menu-active-color;
      border-top: 0;
      &::after {
        content: ' ';
        background-color: g.$admin-menu-active-color;
        width: 10px;
        height: 100%;
        position: absolute;
        right: calc(0px - 2rem + 8px);
        border-radius: 5px 0px 0px 5px;
        top: 0px;
      }
    }
    border-radius: 10px;
    border-bottom: none;
    a,
    button {
      &:focus {
        box-shadow: none !important;
      }
      color: g.$admin-menu-color !important;
      padding: 0px;
    }
  }
}

.admin-icon {
  border-radius: 10px;
  display: inline-block;
  padding: 0.5rem 0.83rem;
}
.admin-icon-primary {
  color: g.$admin-menu-color;
  background-color: g.$admin-menu-active-color;
}
.admin-icon-warning {
  color: g.$admin-color-warning;
  background-color: g.$admin-color-bg-warning;
}
.admin-icon-info {
  color: g.$admin-color-info;
  background-color: g.$admin-color-bg-info;
}

.table {
  thead th,
  td,
  th {
    border: none;
  }
}

.add-button {
  min-width: 13rem;
  border-bottom-left-radius: 0rem;
  border-top-left-radius: 0rem;
  @include u.media-breakpoint-down(md) {
    min-width: 7rem;
  }
}
