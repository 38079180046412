@use '../util/' as u;

html {
  scroll-behavior: smooth;
}

body {
  font-family: u.$font;
}

a,
a:active {
  color: #292b2c;
  text-decoration: none;
}

.btn-link:focus {
  box-shadow: none !important;
}
